import { Link } from "gatsby";
import React from "react";
import HomeSecondaryTitle from "./home-secondary-title";

// HOME COMPONENT - Mini manifesto in home
const HomeSubtitle = (props: {
  currentTheme: 'light' | 'dark',     // -> current theme 
  userLocale: string | undefined,
  textContent: any | undefined
}) => {

  return (
    <div className="flex flex-col items-center justify-center min-h-screen px-4 lg:px-32 md:px-16 3xl:w-[72%]">
      <HomeSecondaryTitle 
        smallTitle={props?.textContent && props?.textContent.title} 
        mainContent={props?.textContent && props?.textContent.description}
        section={1}/>
      <div className="flex w-full h-8 mt-8 lg:h-12 lg:mt-20">
        <Link to="/manifesto">
          <div id='manifest-button' className={`font-bold duration-300 flex items-center border-2 justify-center h-full uppercase text-xs rounded-full w-max lg:text-sm px-3
            ${props?.currentTheme && props.currentTheme == 'light' ? 'cardinal-button' : 'cardinal-button-dark'}`}>
              { props?.userLocale && (props.userLocale.includes('it') || props.userLocale.includes('IT')) ? 'leggi il nostro manifesto' : 'read our manifesto'}
          </div>
        </Link>
      </div>
    </div>
  )

}

export default HomeSubtitle;
